import React, { useState } from 'react';
import styles from './MediaOverlay.module.css';
import CustomButton from '../CustomButton';

const MediaOverlay = ({
  mediaType,
  mediaSrc,
  headingTextSecondary,
  headingTextPrimary,
  descriptionText,
  primaryButtonText,
  secondaryButtonText,
  primaryAction,
  secondaryAction,
  style,
  loadingImage, // Add a prop for the loading image
}) => {
  const [loading, setLoading] = useState(true);
  const [mediaError, setMediaError] = useState(false);

  const handleLoadedData = () => {
    setLoading(false);
  };

  return (
    <div
      className={styles.mediaOverlayContainer}
      style={{
        ...style,
        backgroundImage: loading ? `url(${loadingImage})` : 'none', // Show the image while loading
        height: loading || mediaError ? '700px' : 'auto',
      }}
    >
      {/* Always visible overlay */}
      <div className={styles.redOverlay}></div>
      {mediaType === 'video' ? (
        <video
          id="media-element"
          className={styles.media}
          autoPlay
          muted
          playsInline
          loop
          onLoadedData={handleLoadedData}
          onError={() => {
            setLoading(false);
            setMediaError(true);
          }}
          style={{ display: loading ? 'none' : 'block' }}
        >
          <source src={mediaSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <img
          id="media-element"
          src={mediaSrc}
          alt="media"
          className={styles.media}
          onLoad={handleLoadedData}
          onError={() => {
            setLoading(false);
            setMediaError(true);
          }}
          style={{ display: loading ? 'none' : 'block' }}
        />
      )}

      {/* Overlay Content */}
      <div className={styles.overlayContent}>
        <h1 className={styles.headingTextPrimary}>{headingTextPrimary}</h1>
        <h1 className={styles.headingTextSecondary}>{headingTextSecondary}</h1>
        <p className={styles.descriptionContent}>{descriptionText}</p>
        <CustomButton
          text={primaryButtonText}
          onClick={primaryAction}
          className={styles.customButtonFirst}
        />
        <CustomButton
          text={secondaryButtonText}
          onClick={secondaryAction}
          className={styles.customButton}
        />
      </div>
    </div>
  );
};

export default MediaOverlay;
