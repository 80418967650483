import React from 'react';
import styles from './CustomButton.module.css';

const CustomButton = ({ text, onClick, className, style }) => {
  return (
    <button
      className={`${styles.customButton} ${className}`} // Merge custom and additional styles
      onClick={onClick}
      style={style}
    >
      {text}
    </button>
  );
};

export default CustomButton;
