import React, { useEffect, useState } from "react";
import styles from "./Services.module.css";
import { Col, Container, Row } from "react-bootstrap";
import Boutique from "../../assets/images/solutions/boutique.png";
import Family from "../../assets/images/solutions/family.png";
import Implants from "../../assets/images/solutions/implants.png";
import Hygiene from "../../assets/images/solutions/hygien.png";
import Invisalign from "../../assets/images/solutions/invisalign.png";
import MinorSugar from "../../assets/images/solutions/minorSugar.png";
import Orthodontics from "../../assets/images/solutions/orthodontics.png";
import Restorative from "../../assets/images/solutions/restorative.png";
import solutions from "../../assets/images/home/SOLUTIONS.png";
import Smile from "../../assets/images/solutions/smile.png";
import Experience from "../../assets/images/home/experience.png";
import Check from "../../assets/images/home/check.png";
import Clinic from "../../assets/images/home/clinic.png";
import Chatboat from "../../assets/images/home/chatBoat.png";
import Pic1 from "../../assets/images/services/service1.jpeg";
import Pic2 from "../../assets/images/services/service2.jpeg";
import Pic3 from "../../assets/images/services/service3.jpeg";
import GetInTouch from "../../components/GetInTouch";
import { useNavigate } from "react-router-dom";

const Services = () => {
  const [temp, setTemp] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleBooking = () => {
    window.open("https://oaklands-dental.dentr.net/book", "_blank");
  };
  return (
    <div>
      {/* <Container style={{ marginTop: 125 }} fluid="sm">
        <p className={styles.services}>Services</p>
        <Row>
          <Col />
          <Col lg={9} sm={12}>
            <p className={styles.text}>
              We offer a wide range of dental services to keep your smile
              beautiful. Our experts are committed to delivering personalized
              care tailored to your needs.
            </p>
          </Col>
          <Col />
        </Row> */}
        {/* <div style={{ textAlign: "center", marginTop: 10 }}>
          <button className={styles.button} onClick={handleBooking}>
            Book an Appointment
          </button>
        </div> */}
      {/* </Container> */}
      <div className={styles.solutionsContainer0}>
        <Container className={styles.headingContainer}>
          <div style={{ position: "relative" }}>
            <div style={{ position: "absolute" }}>
              <img
                src={solutions}
                style={{ textAlign: "center", width: "100%" }}
                alt="solutions"
              />
            </div>
          </div>
          <p className={styles.solutionsHeading}>OUR DENTAL SERVICES</p>
        </Container>
        <Container className={styles.solutionsContainer}>
          <Row>
            <Col
              sm={12}
              lg={4}
              className={styles.solutionsCol}
              onClick={() => navigate("/services/implants")}
            >
              <div className={styles.solutionIcons}>
                <img src={Implants} height={116} alt="solutions-icon" />
              </div>
              <Container>
                <p className={styles.solutionsTitle}>Implants</p>
                <p className={styles.sectionText}>
                  Wish to replace missing teeth with dental implants. Get it
                  fixed now with our long-lasting and natural-looking solution
                  that restores your smile.
                </p>
              </Container>
            </Col>
            <Col
              sm={12}
              lg={4}
              className={styles.solutionsCol}
              onClick={() => navigate("/services/smile")}
            >
              <div className={styles.solutionIcons}>
                <img src={Smile} height={116} alt="solutions-icon" />
              </div>
              <Container>
                <p className={styles.solutionsTitle}>Smile Design</p>
                <p className={styles.sectionText}>
                  A healthy smile comes first above all. Are you all ready to
                  reshape and enhance your teeth with our best treatments?
                  Contact us now to book your appointment.
                </p>
              </Container>
            </Col>
            <Col
              sm={12}
              lg={4}
              className={styles.solutionsCol}
              onClick={() => navigate("/services/clear-aligners")}
            >
              <div className={styles.solutionIcons}>
                <img src={Invisalign} height={116} alt="solutions-icon" />
              </div>
              <Container>
                <p className={styles.solutionsTitle}>CLEAR ALIGNERS</p>
                <p className={styles.sectionText}>
                  Get the perfect smile by straightening your teeth with our
                  quality Invisalign clear aligners. We use breakthrough
                  technology to give you the smile you have always wanted.
                </p>
              </Container>
            </Col>
          </Row>
        </Container>
        <Container className={styles.solutionsRow2}>
          <Row>
            <Col
              sm={12}
              lg={4}
              className={styles.solutionsCol}
              onClick={() => navigate("/services/composite-bonding")}
            >
              <div className={styles.solutionIcons}>
                <img src={Hygiene} height={116} alt="solutions-icon" />
              </div>
              <Container>
                <p className={styles.solutionsTitle}>Composite Bonding</p>
                <p className={styles.sectionText}>
                  Get proper care with our minimally invasive cosmetic treatment
                  that significantly improves your smile.
                </p>
              </Container>
            </Col>
            <Col
              sm={12}
              lg={4}
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/services/teeth-whitening")}
            >
              <div className={styles.solutionIcons}>
                <img src={Boutique} height={116} alt="solutions-icon" />
              </div>
              <Container>
                <p className={styles.solutionsTitle}>TEETH WHITENING</p>
                <p className={styles.sectionText}>
                  Brighten up your smile with our teeth whitening treatment
                  which is the safest and most effective solution tailored to
                  your needs and sensitivity level.
                </p>
              </Container>
            </Col>
            <Col
              sm={12}
              lg={4}
              className={styles.solutionsCol}
              onClick={() => navigate("/services/restorative-dentistry")}
            >
              <div className={styles.solutionIcons}>
                <img src={Restorative} height={116} alt="solutions-icon" />
              </div>
              <Container>
                <p className={styles.solutionsTitle}>Restorative Dentistry</p>
                <p className={styles.sectionText}>
                  We intend to focus on treating damaged teeth with our
                  exceptional restorative dentistry services. We offer crowns,
                  bridges, fillings, and implants to repair damaged parts and
                  enhance your oral health.
                </p>
              </Container>
            </Col>
          </Row>
        </Container>
        <Container
          className={styles.solutionsRow2}
          style={{ paddingBottom: 42 }}
        >
          <Row>
            <Col
              sm={12}
              lg={4}
              className={styles.solutionsCol}
              onClick={() => navigate("/services/family-care")}
            >
              <div className={styles.solutionIcons}>
                <img src={Family} height={116} alt="solutions-icon" />
              </div>
              <Container>
                <p className={styles.solutionsTitle}>Family Care</p>
                <p className={styles.sectionText}>
                  We aim to provide families with the best treatment options so
                  they feel comfortable and confident in our care. Reach out to
                  us now.
                </p>
              </Container>
            </Col>
            <Col
              sm={12}
              lg={4}
              className={styles.solutionsCol}
              onClick={() => navigate("/services/minor-oral-surgery")}
            >
              <div className={styles.solutionIcons}>
                <img src={MinorSugar} height={116} alt="solutions-icon" />
              </div>
              <Container>
                <p className={styles.solutionsTitle}>Minor Oral Surgery</p>
                <p className={styles.sectionText}>
                  Enjoy personalized care to reduce discomfort and pain with the
                  help of our experts. We utilize advanced techniques and
                  technologies to ensure a seamless experience for every
                  customer.
                </p>
              </Container>
            </Col>
            <Col
              sm={12}
              lg={4}
              className={styles.solutionsCol}
              onClick={() => navigate("/services/sedation")}
            >
              <div className={styles.solutionIcons}>
                <img src={Orthodontics} height={116} alt="solutions-icon" />
              </div>
              <Container>
                <p className={styles.solutionsTitle}>Sedation</p>
                <p className={styles.sectionText}>
                  Experience top-notch dental care designed for patients with
                  anxiety or phobia about routine visits.
                </p>
              </Container>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row style={{ marginTop: 50 }}>
            <Col sm>
              <img
                src={Pic1}
                style={{ width: "100%", height: "auto" }}
                alt="pic1"
              />
            </Col>
            <Col sm>
              <img
                src={Pic2}
                style={{ width: "100%", height: "auto" }}
                alt="pic1"
              />
            </Col>
            <Col sm>
              <img
                src={Pic3}
                style={{ width: "100%", height: "auto" }}
                alt="pic1"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.getTouchContainer}>
        <GetInTouch />
      </div>
    </div>
  );
};

export default Services;
