import { Route, Routes } from "react-router-dom";
import React from "react";
import Home from "../pages/home";
import AboutUs from "../pages/about-us/";
import Services from "../pages/services";
import Information from "../pages/information";
import ContactUs from "../pages/contact-us";
import Team from "../pages/team";
import Testimonials from "../pages/testimonials";
import BoutiqueWhitening from "../pages/boutique-whitening";
import Invisalign from "../pages/Invisalign";
import HygieneService from "../pages/composite-bonding";
import SmileDesign from "../pages/smile-design";
import RestorativeDentistry from "../pages/restorative-dentistry";
import FamilyCare from "../pages/family-care";
import MinorOralSurgery from "../pages/minor-oral-surgery";
import Implants from "../pages/implants";
import Documents from "../pages/documents";
import Pricing from "../pages/pricing";
import Blogs from "../pages/blogs";
import ModernSlavery from "../pages/modern-slavery";
import Emergency from "../pages/emergency";
import Sedation from "../pages/sedation";
import CompositeBonding from "../pages/composite-bonding";
import OralHealthCare from "../pages/blogs/oral-health-care";
import PreventionDentistry from "../pages/blogs/prevention-dentistry";
import OralHygieneTips from "../pages/blogs/oral-hygiene-tips";
import Orthodontics from "../pages/blogs/orthodontics";
import PostOrthodonticCare from "../pages/blogs/post-orthodontic-care";
import DentalHealthAwareness from "../pages/blogs/dental-health-awareness";
import PediatricDentistry from "../pages/blogs/pediatric-dentistry";

const AllRoutes = () => {
  return (
    <>
      <Routes>
        {/* <Route path="/" exact component={<Login />}></Route> */}
        <Route path="*" element={<Home />}></Route>

        <Route path="/home" element={<Home />}></Route>
        <Route path="/about-us" element={<AboutUs />}></Route>
        <Route path="/aboutus" element={<AboutUs />}></Route>
        <Route path="/about-us/team" element={<Team />}></Route>
        <Route path="/about-us/testimonials" element={<Testimonials />}></Route>
        <Route path="/services" element={<Services />}></Route>
        <Route
          path="/services/teeth-whitening"
          element={<BoutiqueWhitening />}
        ></Route>
        <Route path="/services/clear-aligners" element={<Invisalign />}></Route>
        <Route
          path="/services/composite-bonding"
          element={<CompositeBonding />}
        ></Route>
        <Route path="/services/smile" element={<SmileDesign />}></Route>
        <Route
          path="/services/restorative-dentistry"
          element={<RestorativeDentistry />}
        ></Route>
        <Route path="/services/sedation" element={<Sedation />}></Route>
        <Route path="/services/family-care" element={<FamilyCare />}></Route>
        <Route
          path="/services/minor-oral-surgery"
          element={<MinorOralSurgery />}
        ></Route>
        <Route path="/services/implants" element={<Implants />}></Route>
        <Route path="/information" element={<Pricing />}></Route>
        <Route path="/information/documents" element={<Pricing />}></Route>
        <Route path="/information/privacy" element={<Documents />}></Route>
        <Route path="/information/finance" element={<Pricing />}></Route>
        <Route path="/information/pricing" element={<Pricing />}></Route>
        <Route path="/information/nhs" element={<Pricing />}></Route>
        <Route path="/information/tabeo" element={<Pricing />}></Route>
        <Route path="/information/forpatient" element={<Pricing />}></Route>
        <Route path="/information" element={<Pricing />}></Route>
        <Route
          path="/information/modern-slavery"
          element={<ModernSlavery />}
        ></Route>
        <Route path="/blogs" element={<Blogs />}></Route>
        <Route
          path="/blogs/oral-health-care"
          element={<OralHealthCare />}
        ></Route>
        <Route
          path="/blogs/preventive-dentistry"
          element={<PreventionDentistry />}
        ></Route>
        <Route
          path="/blogs/oral-hygiene-tips"
          element={<OralHygieneTips />}
        ></Route>
        <Route
          path="/blogs/post-orthodontic-care"
          element={<PostOrthodonticCare />}
        ></Route>
        <Route
          path="/blogs/dental-health-awareness"
          element={<DentalHealthAwareness />}
        ></Route>
        <Route
          path="/blogs/pediatric-dentistry"
          element={<PediatricDentistry />}
        ></Route>
        <Route path="/blogs/orthodontics" element={<Orthodontics />}></Route>
        <Route path="/emergency" element={<Emergency />}></Route>
        <Route path="/contact-us" element={<ContactUs />}></Route>
      </Routes>
    </>
  );
};

export default AllRoutes;
