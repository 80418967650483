import React, { useEffect, useState } from "react";
import styles from "./sub-service.module.css";
import { Col, Container, Row } from "react-bootstrap";
import rightArrow from "../../assets/images/right-arrow.png";
import headerGroup2 from "../../assets/images/headerGroup2.png";
import Invisalign1 from "../../assets/images/Invisalign/invis1.png";
import Invisalign2 from "../../assets/images/Invisalign/invis2.png";
import Invisalign3 from "../../assets/images/Invisalign/invis3.png";
import Pic1 from "../../assets/images/services/service1.jpeg";
import Pic2 from "../../assets/images/services/service2.jpeg";
import Pic3 from "../../assets/images/services/service3.jpeg";
import solutionsBackground from "../../assets/images/SOLUTIONS.png";
import GetInTouch from "../../components/GetInTouch";
import { useNavigate } from "react-router-dom";

const SpecificService = ({ title1, title2, desc1, desc2, solutionsTop }) => {
  const navigate = useNavigate();
  const [temp, setTemp] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleBooking = () => {
    window.open("https://oaklands-dental.dentr.net/book", "_blank");
  };
  return (
    <div>
      <Container style={{ marginTop: 125 }}>
        <p className={styles.services}>{title1}</p>
        <Row>
          <Col />
          <Col lg={9} sm={12}>
            <p className={styles.text}>{desc1}</p>
          </Col>
          <Col />
        </Row>
        {/* <div style={{ textAlign: "center", marginTop: 10 }}>
          <button className={styles.button} onClick={handleBooking}>
            Book an Appointment
          </button>
        </div> */}
      </Container>
      <Container>
        <Row className={styles.section2}>
          <Col style={{ marginTop: 15 }}>
            <p className={styles.desc2}>{desc2}</p>
            <div className={styles.buttonContainer}>
              <button
                className={styles.button3}
                onClick={() => navigate("/contact-us")}
              >
                Let’s Start
              </button>
              {/* <div
                style={{
                  display: "flex",
                  gap: 5,
                  width: "-webkit-fill-available",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: 5,
                    width: "-webkit-fill-available",
                  }}
                >
                  <button className={styles.button2}>Read more</button>
                  <img
                    src={rightArrow}
                    height={11}
                    className={styles.rightArrowButton}
                  />
                </div>
              </div> */}
            </div>
          </Col>
          <Col>
            <div className={styles.solutionsImage}>
              <img className={styles.image} src={headerGroup2} />
            </div>
          </Col>
        </Row>
        <Container className={styles.caseStudyContainer}>
          <div style={{ position: "relative" }}>
            <div style={{ position: "absolute" }}>
              <img
                src={solutionsBackground}
                style={{
                  textAlign: "center",
                  width: "100%",
                  marginTop: solutionsTop,
                }}
                alt="solutions"
              />
            </div>
          </div>
          <p className={styles.services2}>{title2}</p>
        </Container>
        {/* <Container className={styles.imagesContainer}>
          <Row>
            <Col className={styles.image}>
              <img src={Invisalign1} className={styles.image2} />
            </Col>
            <Col className={styles.image}>
              <img src={Invisalign2} className={styles.image2} />
            </Col>
            <Col className={styles.image}>
              <img src={Invisalign3} className={styles.image2} />
            </Col>
          </Row>
        </Container> */}
        <Container>
          <Row style={{ marginTop: 200 }}>
            <Col sm>
              <img
                src={Pic1}
                style={{ width: "100%", height: "auto" }}
                alt="pic1"
              />
            </Col>
            <Col sm>
              <img
                src={Pic2}
                style={{ width: "100%", height: "auto" }}
                alt="pic1"
              />
            </Col>
            <Col sm>
              <img
                src={Pic3}
                style={{ width: "100%", height: "auto" }}
                alt="pic1"
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <div className={styles.getTouchContainer}>
        <GetInTouch />
      </div>
    </div>
  );
};

export default SpecificService;
